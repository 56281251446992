<template>
  <div
    id="app"
    style="
      background-repeat: no-repeat;
      background-position: right top;
      background-attachment: fixed;
      background: #f6f6f6;
    "
  >
    <div
      id="wrapper"
      style="background-repeat: no-repeat; background-attachment: fixed"
      class="wrapper-fluid banners-effect-3"
    >
      <!-- <div
      id="wrapper"
      style="background-image: url(https://res.cloudinary.com/persystance-network/image/upload/q_auto/v1589124594/slt/portal_user/1589124594178.png);
  background-repeat: no-repeat; background-attachment: fixed;"
      class="wrapper-fluid banners-effect-3"
      >-->
      <header id="header" class="typeheader-1">
        <!-- <home-page-top-header v-if="showmainheader" class="typeheader-2" /> -->
        <HomePageTopHeaderProfileComingSoon v-if="!showmainheader" class="typeheader-2" />
        <!-- <inner-page-top-header v-if="innerPageShow" /> -->
      </header>
      <div class="main-container">
        <Breadcrumb v-if="showbread" />
        <ul class="breadcrumb" v-if="showbreadblank"></ul>
        <div id="content">
          <home-page-banner v-if="$route.name === 'Buyer Home'" />
          <div class="row content-main-w">
            <div
              class="col-lg-4 col-md-3 col-sm-12 col-xs-12 content-aside main-left"
              v-if="showlefnav"
            >
              <home-page-left-nav-bar />
            </div>
            <div :class="maincontentcss">
              <router-view />
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-if="$route.name === 'Buyer Home'">
        <CustomerServices></CustomerServices>
      </div> -->
      <!-- <div v-if="$route.name === 'Buyer Home'">
        <DiscountBanners></DiscountBanners>
      </div> -->
      <div v-if="$route.name === 'Buyer Home'">
        <ProductGroupSlider></ProductGroupSlider>
      </div>
      <div v-if="$route.name === 'Buyer Home'">
        <div class="container">
          <div class="row" style="background-color: white; margin-bottom: 2.5%">
            <div class="col-md-12" style="padding: 2.5%">
              <h1
                class="title-category"
                style="font-size: 2.6rem; font-weight: 700"
              >
                BRANDS
              </h1>
              <div
                class="slider-brands col-lg-12 col-md-12 col-sm-12 col-xs-12"
              >
                <div
                  class="yt-content-slider contentslider"
                  data-autoplay="no"
                  data-delay="4"
                  data-speed="0.6"
                  data-margin="0"
                  data-items_column00="7"
                  data-items_column0="5"
                  data-items_column1="3"
                  data-items_column2="3"
                  data-items_column3="2"
                  data-items_column4="1"
                  data-arrows="yes"
                  data-pagination="no"
                  data-lazyload="yes"
                  data-loop="no"
                >
                  <div v-for="(item, index) in brandsArray" :key="index">
                    <ProductTileBrand v-bind:data="item"></ProductTileBrand>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <img
        src="https://res.cloudinary.com/persystance-network/image/upload/h_180/v1593688506/slt/portal_user/1593688505930.png"
        style="margin-top: -60px;"
      />-->
      <footer class="footer-container typefooter-1" style="margin-top: 0px">
        <bottom-footer />
      </footer>
    </div>
  </div>
</template>
<script>
// Home Page Special
import HomePageTopHeader from "@/components/common/home_page_top_header.vue";
import HomePageTopHeaderProfile from "./BuyerDashboard/home_page_top_header.vue";
import HomePageTopHeaderProfileComingSoon from "./BuyerDashboard/home_page_top_header_soon.vue";
import HomePageLeftNavBar from "@/components/common/home_page_left_nav_bar.vue";
import HomePageBanner from "@/components/common/home_page_banner.vue";
import Breadcrumb from "@/components/common/breadcrumb.vue";
import ProductTileGrid from "@/components/product/product_tile_grid.vue";
import ProductTileBrand from "@/components/product/product_tile_brand.vue";
import ProductGroupSlider from "@/components/product/product_group_slider.vue";
// import CustomerServices from "@/components/product/customer_services.vue";
// import DiscountBanners from "@/components/product/discount_banners.vue";

// For All Pages
// import BottomFooter from "@/components/common/footer.vue";
// import BottomFooter from "@/components/common/footerNew.vue";
import BottomFooter from "@/components/common/footerNewSoon.vue";
// For Inner Pages
import InnerPageTopHeader from "@/components/common/home_page_top_header.vue";

import mixinAdvertisments from "@/mixins/APICommon";

// import InnerPageLeftNavBar from '@/components/common/home_page_left_nav_bar.vue'
export default {
  name: "app",
  components: {
    HomePageTopHeader,
    HomePageTopHeaderProfile,
    HomePageTopHeaderProfileComingSoon,
    BottomFooter,
    HomePageLeftNavBar,
    HomePageBanner,
    InnerPageTopHeader,
    ProductTileGrid,
    ProductTileBrand,
    ProductGroupSlider,
    // CustomerServices,
    // DiscountBanners,
    // InnerPageLeftNavBar,
    Breadcrumb,
  },
  mixins: [mixinAdvertisments],
  data() {
    return {
      homePageShow: false,
      innerPageShow: false,
      showbread: false,
      showbreadblank: false,
      showlefnav: true,
      showmainheader: true,
      maincontentcss: "col-lg-10 col-md-9 col-sm-12 col-xs-12 main-right",
      brandsArray: [
        {image:"https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png"},
        {image:"https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png"},
        {image:"https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png"},
        {image:"https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png"},
        {image:"https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png"},
        {image:"https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png"},
        {image:"https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png"},
      ],
    };
  },
  watch: {
    $route(to, from) {
      this.BreadcrumbStatus();
      this.LeftsideStatus();
      this.headerStatus();
      this.zoomContainerRemove();
    },
  },
  beforemounted() { 
    /* ---------------------------------------------------
      Back to Top
    -------------------------------------------------- */
    $(".back-to-top").addClass("hidden-top");
    $(window).scroll(function () {
      if ($(this).scrollTop() === 0) {
        $(".back-to-top").addClass("hidden-top");
      } else {
        $(".back-to-top").removeClass("hidden-top");
      }
    });

    $(".back-to-top").click(function () {
      $("body,html").animate({ scrollTop: 0 }, 1200);
      return false;
    });
  },
  mounted() {
    this.checkRoute();
    this.BreadcrumbStatus();
    this.LeftsideStatus();
    this.headerStatus();
    this.zoomContainerRemove();
  },
  computed: {},
  created() {
    this.haddleGetToBrands();
  },
  methods: {
    zoomContainerRemove() {
      $(".zoomContainer").each(function () {
        //if statement here
        // use $(this) to reference the current div in the loop
        //you can try something like...
        $(this).remove();
      });
    },
    checkRoute() {
      if (this.$route.name === "Buyer Home") {
        this.homePageShow = true;
      } else {
        this.homePageShow = false;
      }
    },
    LeftsideStatus() {
      if (
        [
          "Buyer Home",
          "product",
          "buyer login",
          "buyer register",
          "Buyer Cart",
          "Buyer Checkout",
          "category",
          "Flash Deal",
          "Coming Soon",
          "Product Search",
          "Brand Search",
          "About Us",
          "Contact Us",
          "Subscribe",
          "E Teleshop"
        ].includes(this.$route.name)
      ) {
        this.showlefnav = false;
        this.maincontentcss = "col-lg-12 col-md-12 col-sm-12 col-xs-12";
        // if(this.$route.name === 'product'){
        //   this.maincontentcss = 'col-lg-12 col-md-12 col-sm-12 col-xs-12';
        // } else {
        //   this.maincontentcss = 'col-lg-10 col-md-9 col-sm-8 col-xs-12 main-right';
        // }
      } else {
        this.maincontentcss =
          "col-lg-9 col-md-9 col-sm-12 col-xs-12 main-right new-card-outer";
        this.showlefnav = true;
      }
    },
    headerStatus() {
      if (
        [
          "Buyer Home",
          "product",
          "buyer login",
          "buyer register",
          "Buyer Cart",
          "Buyer Checkout",
          "category",
          "Flash Deal",
          "Product List",
          "Product Search",
        ].includes(this.$route.name)
      ) {
        this.showmainheader = true;
      } else {
        this.showmainheader = false;
      }
    },
    BreadcrumbStatus() {
      if (
        // ["Buyer Home", "buyer login", "buyer register", "product"].includes(
        ["buyer login", "buyer register"].includes(this.$route.name)
      ) {
        this.showbread = false;
      } else {
        this.showbread = true;
      }
    },
    BreadcrumbStore(breadcrumbData) {},
    haddleGetToBrands: async function(){
      try {
        let response = await this.getTopBrands();
        let arr = [];
        for (let index = 0; index < response.length; index++) {
          const element = response[index];
          let obj = {};
          if (element._id.logo_img) {
            obj.image = element._id.logo_img;
            obj._id = element._id._id;
            obj.brand_name = element._id.brand_name;
          } else {
            obj.image = null;
            obj._id = element._id._id;
            obj.brand_name = element._id.brand_name;
          }
          arr.push(obj);
        }
        this.brandsArray = arr;
      } catch (error) {
        throw error;
      }
    }
  }
};
</script>
<style>
body {
  padding-right: 0px !important;
}

.slider-brands {
  margin-bottom: 0px !important;
}

#slider-group {
  border: none !important;
}
.new-card-outer {
  background: #ffffff;
  border-radius: 8px;
}

@media (max-width: 992px) {
  .slider-brands {
    margin-bottom: 50px !important;
  }
}

@media (min-width: 768px) {
  .classWell {
    min-height: 80vh;
  }
}

@media (min-width: 1280px) {
  .classWell {
    min-height: 85.6vh;
  }
}
@media (min-width: 1440px) {
  .classWell {
    min-height: 76vh;
  }

  .content-aside {
    width: 20.9666% !important;
  }
}
@media (min-width: 1800px) {
  .classWell {
    min-height: 38vh;
  }

  .content-aside {
    width: 20.9666% !important;
  }
}
@media (min-width: 1858px) {
  .classWell {
    min-height: 71vh;
  }
  .content-aside {
    width: 20.9666% !important;
  }
}
@media (min-width: 1880px) {
  .classWell {
    min-height: 38vh;
  }

  .content-aside {
    width: 20.9666% !important;
  }
}
@media (min-width: 2880px) {
  .classWell {
    min-height: 38vh;
  }

  .content-aside {
    width: 20.9666% !important;
  }
}
</style>
