<template>
  <div v-if="mostPopularItems.length > 0" class="container">
    <div class="row" style="background-color: white; margin-bottom: 2.5%">
      <div class="col-md-12" style="padding: 2.5%">
        <h1 class="title-category" style="font-size: 2.6rem; font-weight: 700">
          MOST POPULAR
        </h1>
        <div
          class="slider-brands col-lg-12 col-md-12 col-sm-12 col-xs-12"
          id="slider-group"
        >
          <div
                  id="productGroup"
                  class="yt-content-slider contentslider"
                  data-rtl="yes"
                  data-autoplay="yes"
                  data-delay="4"
                  data-speed="0.6"
                  data-margin="0"
                  data-items_column00="4"
                  data-items_column0="4"
                  data-items_column1="3"
                  data-items_column2="2"
                  data-items_column3="2"
                  data-items_column4="1"
                  data-arrows="no"
                  data-pagination="no"
                  data-lazyload="yes"
                  data-loop="no"
                >
            <div v-for="(item, index) in mostPopularItems" :key="index">
              <ProductTileGroup v-if="item.images" v-bind:data="item"></ProductTileGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductTileGroup from "@/components/product/product_tile_group.vue";
import mixinCommon from "@/mixins/APICommon";
export default {
  components: {
    ProductTileGroup,
  },
  mixins: [mixinCommon],
  data() {
    return {
      mostPopularItems: [
        {
          image1:
            "https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png",
          image2:
            "https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png",
          image3:
            "https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png",
        },
        {
          image1:
            "https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png",
          image2:
            "https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png",
          image3:
            "https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png",
        },
        {
          image1:
            "https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png",
          image2:
            "https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png",
          image3:
            "https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png",
        },
        {
          image1:
            "https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png",
          image2:
            "https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png",
          image3:
            "https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png",
        },
        {
          image1:
            "https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png",
          image2:
            "https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png",
          image3:
            "https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1583924174/slt/portal_user/1583924174301.png",
        }
      ]
    };
  },
  created() {
    this.getMostPopuler();
  },
  methods: {
    getMostPopuler: async function() {
      try {
        let response = await this.getMostPopulerCatList();
        this.mostPopularItems = response;

        // console.log(this.mostPopularItems);


      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
<style>
/* #productGroup .owl2-item { */
  /* width: 440px !important; */
  /* margin-right: 20px !important; */
/* } */
#productGroup {
  max-height: 200px !important;
  overflow: hidden !important;
}

#slider-group {
  /* max-height: 209px !important;  */
  overflow: hidden !important;
}
</style>