var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"newFooter"}},[_c('div',{staticClass:"container footer-wrapper"},[_c('div',{staticClass:"row rowcontainer"}),_c('div',{staticClass:"row rowcontainer"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 footer-top-section"},[_c('div',{staticClass:"row rowcontainer"},[_c('div',{staticClass:"col-lg-2 col-md-3 col-sm-6 col-xs-12 footer-navlinks"},[_vm._m(0),_c('ul',[(this.$store.state.buyer_accessToken !== null)?_c('li',[_c('a',[_vm._v(" My Account ")])]):_vm._e(),(this.$store.state.buyer_accessToken === null)?_c('li',{staticClass:"log login"},[_c('a',{staticClass:"link-lg"},[_vm._v(" My Account ")])]):_vm._e(),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])]),_vm._m(6),_vm._m(7),_c('div',{staticClass:"col-lg-2 col-md-2 col-sm-12 col-xs-12 download-app"},[_c('img',{staticClass:"cochchiLogo",attrs:{"src":this.$store.state.colourLogo}})])])])])]),_vm._m(8),_c('BuyerLoginModal',{ref:"active"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('b',[_vm._v("USEFUL LINKS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',[_vm._v(" About Us ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',[_vm._v("Contact Us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"./PrivacyPolicy.html","target":"_blank"}},[_vm._v("Privacy Policy")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"./TermsAndConditions.html","target":"_blank"}},[_vm._v(" Terms And Conditions ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://www.slt.lk","target":"_blank"}},[_vm._v("Corporate Website")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 col-md-3 col-sm-6 col-xs-12 contact-section"},[_c('h2',[_c('b',[_vm._v("CONTACT INFORMATION")])]),_c('div',{staticClass:"email-wrapper"},[_c('div',{staticClass:"email-icon"},[_c('i',{staticClass:"fa fa-envelope",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"email-content",staticStyle:{"color":"gray","font-size":"14px"}},[_vm._v("info@cochchi.lk")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 col-md-4 col-sm-12 col-xs-12 download-app"},[_c('h2',[_c('b',[_vm._v("DOWNLOAD THE APP")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-sm-6 col-xs-6 text-rigth-sm"},[_c('img',{staticClass:"store-img-playstore",attrs:{"src":"/assets/image/playstore.png"}})]),_c('div',{staticClass:"col-md-4 col-sm-6 col-xs-6"},[_c('img',{staticClass:"store-img-appstore",attrs:{"src":"/assets/image/appstore.png"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid footer-wrapper"},[_c('div',{staticClass:"row rowcontainer"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 footer-bottom-section"},[_c('span',{staticClass:"footer-copyright-text"},[_vm._v("©Cochchi.lk2021.All rights reserved, Cochchi.lk From SLT-Mobitel, Platform partner Supreme")])])])])
}]

export { render, staticRenderFns }