<template>
  <div>

    <!-- Header middle starts -->
    <div
      class="header-middle"
      style="margin-bottom: 0px; background: linear-gradient(98deg, rgba(0,104,181,1) 0%, rgba(20,84,161,1) 33%, rgba(228,109,42,1) 88%, rgba(250,179,11,1) 100%) !important"
    >
      <div class="container" id="comminSoonContainer">
        <div class="row">
          <div class="bottom1 col-lg-2 col-md-2 col-sm-2 col-xs-2 logosection-dashboard" id="comminglogosection">
            <div>
              <!-- <div class="nav-logo"> -->
              <a style="cursor: default !important;">
                <!-- <img
                  src="https://res.cloudinary.com/persystance-network/image/upload/v1593918873/slt/portal_user/1593918873513.png"
                  alt="mdb logo"
                  style="padding: 20px;"
                />-->
                <img
                   :src="this.$store.state.colourLogo"
                  alt="mdb logo"
                  style="border-radius: 10px; width: auto;"
                />
              </a>
            </div>
          </div>
          <div
            id="commn-search-col"
            class="searchdiv buyer-prof-searchdiv col-lg-5 col-md-5 col-sm-5 col-xs-5 new-search-area-block"
          >
            <div class="search-header-w">
              <div id="sosearchpro" class="sosearchpro-wrapper so-search">
                <div id="search0" class="search input-group form-group">
                  <div
                    class="select_category"
                    style="background-color:transparent; border-right: 0px solid #d7d7d7;"
                  ></div>
                  <input
                    @keyup="validateSearch()"
                    v-on:keyup.enter="pushSearch()"
                    id="common-search-input"
                    class="autosearch-input form-control"
                    type="text"
                    size="50"
                    v-model="searchtxt"
                    autocomplete="off"
                    placeholder="Search"
                    disabled
                  />
                  <button
                    type="button"
                    :disabled="searchdisable"
                    class="btn"
                    name="submit_search"
                    @click="pushSearch()"
                  >
                    <!-- style="padding-left:18px;padding-right:18px;background-color:white; border-left: 1px solid black;" -->
                    <i
                      style="color: white; font-size: 16px;"
                      class="fa fa-search"
                    ></i>
                  </button>
                </div>
                <input type="hidden" name="route" value="product/search" />
              </div>
            </div>
          </div>

          <!-- start -->
          <div
            class="cartbuttondiv buyer-prof-cartbuttondiv  col-lg-5 col-md-5 col-sm-5 col-xs-5 new-icon-area-block"
          >
            <!-- <div class="col-lg-4 col-md-4 col-sm-4"> -->
            <!-- hidden-md hidden-sm hidden-xs  -->

            <div v-if="no_of_cart_items >= 0" class="shopping_cart shopping_cart_profhdr">
              <div
                id="cart"
                class="btn-shopping-cart btn-shopping-cart_profhdr"
                style="margin-top: -5px;"
              >
                <a
                  style="cursor: default !important;"
                  data-loading-text="Loading... "
                  @click="logToCart"
                  class="btn-group top_cart dropdown-toggle"
                  data-toggle="dropdown"
                  aria-expanded="true"
                >
                  <div class="shopcart shopcart_profhd">
                    <ul class="wishlist-comp wishlist-comp_profhd" style="margin-right: 0px;">
                      <li
                        class="wishlist"
                        style="margin-left: 0px; width: auto;"
                      >
                           <div class="cart-count-block buyer-prof-cart-count-block" :style="no_of_cart_items === 0 ? 'pointer-events: none; opacity: 0.4' : ''">
                             <div class="shopcart-inner" @click="cart">
                              <span class="total-shopping-cart cart-total-full">
                                <span class="items_cart buyer-prof-items_cart cart-item-count" style="top: 12px;">{{ no_of_cart_items }}</span>
                                <span class="items_cart2">item(s)</span>
                              </span>
                              <i class="fa fa-shopping-bag" aria-hidden="true"></i>
                            </div>
                           </div>
                           <div class="my-cart-btn-block">
                              <button
                                  class="btn my-cart-btn"
                                  style="font-size: 14px;"
                                  :disabled="no_of_cart_items === 0"
                              >
                                 My Cart
                              </button>
                           </div> 
                      </li>
                    </ul>
                  </div>
                </a>
                <ul
                  v-if="no_of_cart_items !== 0"
                  class="dropdown-menu pull-right shoppingcart-box cart-dropdown"
                  role="menu"
                  style="margin-top: 2px;"
                >
                  <li>
                    <table class="table table-striped table-cart">
                      <tbody>
                        <tr
                          v-for="(itemp, index) in cart_products"
                          :key="index"
                        >
                          <td class="text-center" style="width:70px">
                            <a href="#">
                              <img
                                :src="itemp.imgpath[0]"
                                style="width:70px"
                                alt="Yutculpa ullamcon"
                                title="Yutculpa ullamco"
                                class="preview"
                              />
                            </a>
                          </td>
                          <td class="text-left">
                            <a
                              class="cart_product_name"
                              @click="pushToItem(itemp.item_id)"
                              >{{ itemp.product_name }}</a
                            >
                          </td>
                          <td class="text-center">
                            x{{ itemp.primary_product.selected_qty }}
                          </td>
                          <td class="text-center">
                            {{
                              itemp.primary_product.special_price *
                                itemp.primary_product.selected_qty
                            }}
                          </td>
                          <td class="text-right"></td>
                          <td class="text-right">
                            <a
                              @click="removeCartItems(itemp, index)"
                              class="fa fa-times fa-delete"
                            ></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                  <li>
                    <div>
                      <p class="text-right">
                        <button
                          @click="cart"
                          style="margin-right:10px; margin-top:10px; color: #fff; background-color: #005baa;"
                          class="img-thumbnail verified-badge view-cart"
                        >
                          <div class="tick-span">
                            <span style="border-radius:50%;">
                              <i
                                class="fa fa-shopping-cart"
                                aria-hidden="true"
                              ></i> </span
                            >&nbsp;View Cart
                          </div>
                        </button>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- end -->


            <!-- Hidden on 21-10-2020 -->
          <!-- <div
            id="hometabs"
            class="col-lg-12 col-md-12 col-sm-12 col-xs-12 banner-top-nav banner-top-nav-new buyer-prof-linktab"
          > -->
            <!-- <div id="hometabsbuttons">
              <router-link
                :to="{
                  name: 'Product List Home Tabs',
                  query: { type: 'offers' },
                }"
              >
                <button class="btn addToCartNew" style="margin-right: 2px;">
                  OFFERS
                </button>
              </router-link>
              <router-link
                :to="{ name: 'Product List Home Tabs', query: { type: 'new' } }"
              >
                <button class="btn addToCartNew" style="margin-right: 2px;">
                  NEW ARRIVALS
                </button>
              </router-link>
              <router-link
                :to="{
                  name: 'Product List Home Tabs',
                  query: { type: 'popular' },
                }"
              >
                <button class="btn addToCartNew" style="margin-right: 2px;">
                  MOST POPULAR
                </button>
              </router-link>
            </div> -->

            <!-- <div
              class="signin-w new-signin-w"
              style="padding-top: 5px;padding-left: 0px;margin-left: 2%;"
            >
              <ul class="signin-link blank">
                <li
                  v-if="this.$store.state.buyer_accessToken === null"
                  class="log login"
                >
                  <a v-on:mouseup="loginNew" class="link-lg">
                    <B class="text-color-headder login-signup-text">Login | Signup</B>
                  </a>
                </li>
                <li
                  v-if="this.$store.state.buyer_accessToken !== null"
                  class="log login"
                > -->
                  <!-- <i class="fa fa-user text-color-headder" style="font-size:14px;"></i>
                  <a
                    v-if="buyer !== 'undefined undefined'"
                    @click="handleProfile"
                    class="user-name"
                  >
                    &nbsp;&nbsp;
                    <B class="text-color-headder">{{buyer}}</B>
                  </a>-->
                  <!-- <div
                    id="cart"
                    class="btn-shopping-cart"
                    style="display: inline-block;"
                  >
                    <i
                      class="fa fa-user text-color-headder new-text-color-headder"
                      style="font-size:14px;margin-top: -2px;"
                    ></i>
                    <a
                      v-if="buyer !== 'undefined undefined'"
                      data-loading-text="Loading... "
                      @click="handleProfile"
                      class="btn-group top_cart dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <div class="shopcart">
                        <ul class="wishlist-comp">
                          <li
                            class="wishlist"
                            style="min-width: max-content; margin-top: 0px;padding-top: 0px;height: 35px;line-height: 27px;"
                          >
                            <a>
                              &nbsp;&nbsp;
                              <B
                                id="buyerName"
                                style="vertical-align: sub;"
                                class="text-color-headder"
                                >{{ buyer }}</B
                              >
                            </a>
                          </li>
                        </ul>
                      </div>
                    </a>
                    <ul
                      style="min-width: 100px; right: auto; left: auto;"
                      id="profile-dropdown-menu"
                      class="dropdown-menu pull-right shoppingcart-box"
                      role="menu"
                    >
                      <li>
                        <table
                          class="table table-striped"
                          style="max-width: 133px;"
                        >
                          <tbody>
                            <tr v-if="userRole == 1">
                              <td>
                                <a
                                  style="font-size: 13px;"
                                  class="cart_product_name"
                                  @click="handleProfile"
                                  >Profile</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a
                                  style="font-size: 13px;"
                                  class="cart_product_name"
                                  @click="myOrders"
                                  >My Orders</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a
                                  style="font-size: 13px;"
                                  class="cart_product_name"
                                  @click="wishlist"
                                  >Wishlist</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a
                                  style="font-size: 13px;"
                                  class="cart_product_name"
                                  @click="disputeReturn"
                                  >Dispute & Return</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a
                                  style="font-size: 13px;"
                                  class="cart_product_name"
                                  @click="myReviews"
                                  >My Reviews</a
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                    </ul>
                  </div>
                  <div style="display: inline-block; margin-left: 10px;">
                    <a
                      v-if="buyer === 'undefined undefined'"
                      id="profileLbl"
                      @click="handleProfile"
                      class="user-name"
                      >{{ ProfileLbl }}</a
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <i
                      class="fa fa-unlock text-color-headder new-text-color-headder"
                      style="font-size: 14px;margin-right: 10px;"
                    ></i>
                    <a v-on:mouseup="logout" class="user-name">
                      &nbsp;&nbsp;
                      <B class="text-color-headder new-text-color-headder">Log Out</B>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div> -->



        </div>
      </div>
    </div>

    <!-- Header middle ends -->

    <!-- Buyer prof new header link tab starts 21-10-2020 -->
    <div class="header-top buyer-prof-header-top hidden-compact" style="min-height: 0px; background: linear-gradient(98deg, rgba(0,104,181,1) 0%, rgba(20,84,161,1) 33%, rgba(228,109,42,1) 88%, rgba(250,179,11,1) 100%) !important;  color: white;">
      <div class="container">
        <div class="row">
          <div class="header-top-left col-lg-4 col-md-4 col-sm-4 col-xs-2">
            <div class="hidden-md hidden-sm hidden-xs welcome-msg">
              <!-- Welcome to Supiripola! Wrap new offers / gift every single day on
              Weekends -->
            </div>
            <ul class="top-link list-inline hidden-lg">
              <li class="account" id="my_account">
                <a
                  @click="home"
                  title="My Account "
                  class="btn-xs dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <span class="hidden-xs" style="color: white;">&nbsp;My Account</span>&nbsp;&nbsp;
                  <span class="fa fa-caret-down" style="color: white;"></span>
                </a>
                <ul class="dropdown-menu" >
                  <li
                    v-if="this.$store.state.buyer_accessToken === null"
                    class="log login"
                  >
                    <a data-toggle="modal" data-target="#BuyerLoginModal">
                      <i class="fa fa-pencil-square-o"></i> &nbsp;&nbsp;Login or
                      Register
                    </a>
                  </li>

                  <li
                    v-if="this.$store.state.buyer_accessToken !== null"
                    class="log login"
                  >
                    <a @click="handleProfile" v-if="userRole == 1">
                      <i class="fa fa-user"></i> &nbsp;&nbsp;Profile
                    </a>
                  </li>

                  <li class="log login">
                    <a @click="deliverLanding">
                      <i class="fa fa-truck"></i>
                      &nbsp;&nbsp;Delivery&nbsp;Partner
                    </a>
                  </li>

                  <li class="log login">
                    <a @click="merchantLandingnew">
                      <i class="fa fa-shopping-cart"></i>
                      Sell&nbsp;on&nbsp;Cochchi
                    </a>
                  </li>

                  <li
                    v-if="this.$store.state.buyer_accessToken !== null"
                    class="log login"
                  >
                    <a @click="logout">
                      <i class="fa fa-unlock"></i> &nbsp;&nbsp;Log Out
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div
            class="header-top-right comming-header-top-right collapsed-block col-lg-8 col-md-8 col-sm-8 col-xs-10 buyer-prof-newlink-list"
          >
            <ul class="top-link list-inline lang-curr comming_nav">
              <!-- <li class="sellerLog hidden-md hidden-sm hidden-xs">
                            <div class="btn-group currencies-block">
                                <a @click="merchantLanding">New&nbsp;Arrivals</a>
                            </div>                              
              </li>-->
              <!-- <li class="sellerLog hidden-md hidden-sm hidden-xs">
                            <div class="btn-group currencies-block">
                                <a @click="merchantLanding">Most&nbsp;Viewed</a>
                            </div>                              
              </li>-->
              <!-- <li class="sellerLog hidden-sm hidden-xs">
                <div class="btn-group currencies-block">
                  <a @click="deliverLanding">Delivery&nbsp;Partner</a>
                </div>
              </li> -->
              <!-- <li class="sellerLog">
                            <div class="btn-group currencies-block">
                                <a @click="merchantLanding"> Sell&nbsp;on&nbsp;Cochchi</a>
                            </div>                              
              </li>-->
              <li class="sellerLog hidden-sm hidden-xs">
                <div class="btn-group currencies-block">
                  <a @click="merchantLandingnew">Sell&nbsp;on&nbsp;Cochchi</a>
                </div>
              </li>
              <li v-if="this.$store.state.buyer_accessToken !== null">
                <div class="btn-group currencies-block">
                  <a @click="handleProfile"><b><i class="fa fa-user" aria-hidden="true"></i> {{ buyer }}</b></a>
                </div>
                  <ul id="buyer-prof-newloggedinmain-ul">
                    <li class="list-group-item" v-if="userRole == 1"><a @click="handleProfile"><i class="fa fa-user" aria-hidden="true"></i> Profile</a></li>
                    <li class="list-group-item"><a @click="myOrders"><i class="fa fa-shopping-cart" aria-hidden="true"></i> My Orders</a></li> 
                    <li class="list-group-item"><a @click="wishlist"><i class="fa fa-heart" aria-hidden="true"></i> Wishlist</a></li> 
                    <li class="list-group-item"><a @click="disputeReturn"><i class="fa fa-undo" aria-hidden="true"></i> Dispute & Return</a></li>
                    <li class="list-group-item"><a @click="myReviews"><i class="fa fa-paper-plane" aria-hidden="true"></i> My Reviews</a></li>
                  </ul>
              </li>
              <li v-if="this.$store.state.buyer_accessToken !== null">
                <div class="btn-group currencies-block">
                  <a @mouseup="logout"><b><i class="fa fa-unlock" aria-hidden="true"></i> Logout</b></a>
                </div>
              </li>
              <!-- <li class="sellerLog" v-if="this.$store.state.buyer_accessToken == null">
                <div class="btn-group currencies-block">
                    <a v-on:mouseup="loginNew"><b> Login | Signup</b></a>
                </div>
              </li> -->
              <!-- <li class="currency hidden-sm hidden-xs">
                <div class="btn-group currencies-block">
                  <form
                    action="index.html"
                    method="post"
                    enctype="multipart/form-data"
                    id="currency"
                  >
                    <a
                      class="btn btn-link dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <span class="icon icon-credit"></span
                      >&nbsp;&nbsp;LKR&nbsp;&nbsp;
                      <span class="fa fa-angle-down"></span>
                    </a>
                    <ul class="dropdown-menu btn-xs">
                      <li>
                        <a @click="home">Sri Lankan Rupees - LKR</a>
                      </li>
                    </ul>
                  </form>
                </div>
              </li> -->
              <!-- <li class="language hidden-sm hidden-xs">
                <div class="btn-group languages-block">
                  <form
                    action="index.html"
                    method="post"
                    enctype="multipart/form-data"
                    id="bt-language"
                  >
                    <a
                      class="btn btn-link dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <img
                        src="assets/image/catalog/flags/gb.png"
                        alt="English"
                        title="English"
                      />
                      <span class>&nbsp;&nbsp;English&nbsp;&nbsp;</span>
                      <span class="fa fa-angle-down"></span>
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a @click="home">
                          <img
                            class="image_flag"
                            src="assets/image/catalog/flags/gb.png"
                            alt="English"
                            title="English"
                          />&nbsp;&nbsp;English
                        </a>
                      </li>
                    </ul>
                  </form>
                </div>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
 <!-- Buyer prof new header link tab ends 21-10-2020 -->

  
    <div class="header-bottom hidden-compact" style="background-color: #0065e9">
      <div class="container">
        <div class="row">
          <div class="bottom1 menu-vertical buyer-prof-menu-vertical col-lg-2 col-md-2 col-sm-2 col-xs-2">
            <div class="responsive so-megamenu megamenu-style-dev">
              <div class="so-vertical-menu">
                <nav class="navbar-default">
                  <div class="container-megamenu vertical">
                    <div id="menuHeading">
                      <div class="megamenuToogle-wrapper">
                        <div class="megamenuToogle-pattern">
                          <div
                            class="container cat-list-tab"
                            style="cursor: default; border-top-right-radius: 5px;border-bottom-right-radius: 5px;text-align: left;padding: 0px;margin: 0px;padding-left: 10px;padding-right: 10px;"
                          >
                            <i class="fa fa-bars cat-list-icon" aria-hidden="true"></i>
                            Categories
                            <i class="fa fa-chevron-down cat-list-arrow" aria-hidden="true"></i>
                          </div>
                        </div>
                      </div>
                    </div>
            
                  </div>
                </nav>
              </div>
            </div>
          </div>

          <div class="main-menu-w col-lg-10 col-md-9">
            <div class="responsive so-megamenu megamenu-style-dev">
              <nav class="navbar-default">
                <div class="container-megamenu horizontal open">
                  <div class="navbar-header">
                    <!-- <button
                      type="button"
                      id="show-verticalmenu"
                      data-toggle="dropdown"
                      class="navbar-toggle"
                    >
                      <i class="fa fa-bars"></i>&nbsp;
                      <span>MENU</span>
                    </button>-->
                    <ul class="dropdown-menu menu-profile">
                      <li>
                        <a class="clearfix" @click="handdleClickFlashDeals"
                          >&nbsp;&nbsp;ALL PRODUCTS</a
                        >
                      </li>
                    </ul>
                    <div class="shopcart-inner buyer-prof-shopcart-inner">
                      <p class="text-shopping-cart"></p>
                    </div>
                  </div>
                  <div class="megamenu-wrapper">
                    <span id="remove-megamenu" class="fa fa-times"></span>
                    <div class="memain-menu-wgamenu-pattern">
                      <div class="container-mega">
                        <ul
                          class="megamenu"
                          data-transition="slide"
                          data-animationtime="250"
                        >
                          <li>
                            <p class="close-menu"></p>
                            <a
                              @click="handdleClickSathosa"
                              class="clearfix"
                            ></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>

         <!-- New menu -->
          <div class="col-lg-10 col-md-9 col-sm-9 home-page-menu home-page-menu-inner">
              <ul class="comming_nav">
                <li class="subNavLink"><router-link to="/" exact>Home</router-link></li>
                <li class="subNavLink" ><router-link to="/" exact>Features</router-link></li>
                <li class="subNavLink" ><router-link to="/" exact>Pages</router-link></li>
                <li class="subNavLink" ><router-link to="/" exact>Accessories</router-link></li>
                <li class="subNavLink" ><router-link to="/" exact>Blog</router-link></li>
                <li class="subNavLink"  id="madeSLLiSoon"><a href="#"> <img src="https://res.cloudinary.com/persystance-network/image/upload/v1616564808/cochchi/portal_user/1616564806917.png" alt=""></a></li>
              </ul>
          </div>
        </div>
      </div>
    </div>
    <BuyerLoginModal ref="active" />

    <!-- Track Order Modal -->

    <div
      class="modal fade"
      id="mdlTrackOrder"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Track My Order</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <input
              style="margin-top: 10px;"
              v-model="trackid"
              type="text"
              class="form-control"
              id="input-store"
              placeholder="Order ID"
              value
              name="voucher"
            />
            <br />
            <h4 style="color: red;">{{ trackmessage }}</h4>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              @click="trackMyOrder()"
              style="border: #ffd338; background: #ffd338; color: black;"
              class="btn btn-primary"
            >
              Track
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinCategory from "@/mixins/APICommon";
import mixinProduct from "@/mixins/buyer/APIProduct";
import mixinOrder from "@/mixins/buyer/APIOrder";
import mixinProfile from "../../mixins/buyer/APIProfile";
import BuyerLoginModal from "@/views/Buyer/BuyerLogin/BuyerLloginModal.vue";
import { functions } from "firebase";

export default {
  name: "TopHeader",
  mixins: [mixinCategory, mixinProduct, mixinProfile, mixinOrder],
  data() {
    return {
      homeTabData: [],
      credit: "",
      buyer: "",
      userRole: 1,
      ProfileLbl: "Profile",
      loggedin: false,
      menuloaded: false,
      mainCategory: [],
      no_of_cart_items: 0,
      cart_products: [],
      image: "",
      idObj: {
        ids: [],
      },
      removeItems: [],
      searchtxt: "",
      cid: "",
      searchdisable: true,
      trackid: "",
      trackmessage: "",
      BuyerData: {},
      menubanner: true,
    };
  },
  components: { BuyerLoginModal },
  computed: {
    contactImage: function() {
      if (this.image) {
        return this.image;
      } else {
        return "assets/image/profile/default_profile_image.jpg";
      }
    },
    BuyerDataStore: function() {
      return this.$store.state.buyer_basic_info.buyer_basic_info.payload;
    },
    addToCartObj: {
      get() {
        return this.$store.state.cart.cart.payload;
      },
    },
  },
  watch: {
    addToCartObj: {
      handler: function(val) {
        this.setCartItemsStore(val);
      },
    },
    $route(to, from) {
      this.getLoginInfo();
      this.setCartItems();

      this.handleHeaderTabs();

      this.menuBannerStatus();
    },
    "$store.state.buyer_full_name"() {
      if (
        this.$store.state.buyer_full_name.first_name === undefined &&
        this.$store.state.buyer_full_name.last_name === undefined
      ) {
        this.getLoginInfo();
      } else {
        this.buyer =
          this.$store.state.buyer_full_name.first_name +
          " " +
          this.$store.state.buyer_full_name.last_name;
      }

      this.getLoginInfo();
    },
    "$store.state.buyer_accessToken"() {
      this.getLoginInfo();
    },
  },
  beforemounted() {
    this.menuBannerStatus();

    // Buyer name hovered Dropdown commeneted on 27-10-2020
    // const newLoggedInUlEl = document.querySelector("#buyer-prof-newloggedinmain-ul");
    // var  newLoggedInUlLists = newLoggedInUlEl.querySelectorAll("li");
    // var listCount = newLoggedInUlLists.length;
    // newLoggedInUlLists.forEach((el, index)=>{
    //   if(parseInt(index+1)%2){
    //       el.style.background = "#f7f5f5";
    //   }else{
    //       el.style.background = "#ffffff";
    //   }
    // });

    var itemver = 11;
    var show_itemver = itemver - 1;

    var active = false;
    var hover = false;
    var itemver = 11;
    var show_itemver = itemver - 1;
    $(document).ready(function() {
      $(".vertical .megamenu .loadmore").click(function() {
        if ($(this).hasClass("open")) {
          $("ul.megamenu li.item-vertical").each(function(i) {
            if (i > show_itemver) {
              $(this).slideUp(200);
              $(this).css("display", "none");
            }
          });
          $(this).removeClass("open");
          $(".vertical .megamenu .loadmore").html(
            '<i class="fa fa-plus-square"></i><span class="more-view">Open Categories</span>'
          );
        } else {
          $(".vertical ul.megamenu li.item-vertical").each(function(i) {
            if (i > show_itemver) {
              $(this).slideDown(200);
            }
          });
          $(this).addClass("open");
          $(".vertical .megamenu .loadmore").html(
            '<i class="fa fa-minus-square"></i><span class="more-view">Close Categories</span>'
          );
        }
      });

      $("ul.megamenu li .sub-menu .content .hover-menu ul li").hover(
        function() {
          $(this)
            .children("ul")
            .show();
        },
        function() {
          $(this)
            .children("ul")
            .hide();
        }
      );

      var wd_width = $(window).width();
      if (wd_width <= 991) {
        $("ul.megamenu > li.hover").unbind("mouseenter mouseleave");
        removeWidthSubmenu();
        clickMegaMenu();
      } else {
        $("ul.megamenu > li.hover").unbind("click");
        hoverMegaMenu();
        renderWidthSubmenu();
      }

      $(window).resize(function() {
        var sp_width = $(window).width();
        if (sp_width <= 991) {
          $("ul.megamenu > li.hover").unbind("mouseenter mouseleave");
          removeWidthSubmenu();
          clickMegaMenu();
        } else {
          $("ul.megamenu > li.hover").unbind("click");
          hoverMegaMenu();
          renderWidthSubmenu();
        }
      });

      $("ul.megamenu > li.click").click(function() {
        if (
          $(this)
            .find(".content")
            .is(":visible")
        ) {
          return false;
        }
        active = $(this);
        hover = true;
        var transition = $(this)
          .closest(".megamenu")
          .data("transition");
        var animation_time = $(this)
          .closest(".megamenu")
          .data("animationtime");
        $("ul.megamenu > li").removeClass("active");
        $(this).addClass("active");
        $("ul.megamenu > li")
          .children(".sub-menu")
          .hide();
        $("ul.megamenu > li")
          .find(".content")
          .hide();
        $(this)
          .children(".sub-menu")
          .show();
        if (transition == "slide") {
          $(this)
            .find(".content")
            .show();
        } else if (transition == "fade") {
          $(this)
            .find(".content")
            .fadeIn(animation_time);
        } else {
          $(this)
            .find(".content")
            .show();
        }
        $(this)
          .children(".sub-menu")
          .css("right", "auto");
        if (
          $("html")
            .css("direction")
            .toLowerCase() == "rtl"
        ) {
          var $whatever = $(this).children(".sub-menu");
          var $whatever2 = $($whatever).closest("ul.megamenu");
          if ($whatever.offset().left < $whatever2.offset().left) {
            $(this)
              .children(".sub-menu")
              .css("right", "0");
          }
        } else {
          var $whatever = $(this).children(".sub-menu");
          var ending_right =
            $(window).width() -
            ($whatever.offset().left + $whatever.outerWidth());
          var $whatever2 = $($whatever).closest("ul.megamenu");
          var ending_right2 =
            $(window).width() -
            ($whatever2.offset().left + $whatever2.outerWidth());
          if (ending_right2 > ending_right) {
            $(this)
              .children(".sub-menu")
              .css("right", "0");
          }
        }

        return false;
      });

      $("#show-megamenu").click(function() {
        if ($(".megamenu-wrapper").hasClass("so-megamenu-active"))
          $(".megamenu-wrapper").removeClass("so-megamenu-active");
        else $(".megamenu-wrapper").addClass("so-megamenu-active");
      });
      $("#remove-megamenu").click(function() {
        $(".megamenu-wrapper").removeClass("so-megamenu-active");
        return false;
      });

      $("#show-verticalmenu").click(function() {
        if ($(".vertical-wrapper").hasClass("so-vertical-active"))
          $(".vertical-wrapper").removeClass("so-vertical-active");
        else $(".vertical-wrapper").addClass("so-vertical-active");
      });
      $("#remove-verticalmenu").click(function() {
        $(".vertical-wrapper").removeClass("so-vertical-active");
        return false;
      });

      $("html").on("click", function() {
        $("ul.megamenu > li.click").removeClass("active");
        $("ul.megamenu > li.click")
          .children(".sub-menu")
          .hide();
        $("ul.megamenu > li.click")
          .find(".content")
          .hide();
      });
      $(".close-menu").on("click", function() {
        $(this)
          .parent()
          .removeClass("active");
        $(this)
          .parent()
          .children(".sub-menu")
          .hide();
        $(this)
          .parent()
          .find(".content")
          .hide();
        return false;
      });
    });

    function renderWidthSubmenu() {
      $(".vertical .sub-menu").each(function() {
        value = $(this).data("subwidth");
        if (value) {
          var container_width = $(".container").width();
          var vertical_width = $(".vertical").width();
          var full_width = container_width - vertical_width;
          var width_submenu = (full_width * value) / 100;
          $(this).css("width", width_submenu + "px");
        }
      });
    }
    function removeWidthSubmenu() {
      $(".vertical .sub-menu").each(function() {
        $(this).css("width", "100%");
      });
    }
    function clickMegaMenu() {
      $("ul.megamenu > li.hover").click(function() {
        if (
          $(this)
            .find(".content")
            .is(":visible")
        ) {
          return true;
        }
        active = $(this);
        hover = true;
        var transition = $(this)
          .closest(".megamenu")
          .data("transition");
        var animation_time = $(this)
          .closest(".megamenu")
          .data("animationtime");
        $("ul.megamenu > li").removeClass("active");
        $(this).addClass("active");
        $("ul.megamenu > li")
          .children(".sub-menu")
          .hide();
        $("ul.megamenu > li")
          .find(".content")
          .hide();
        $(this)
          .children(".sub-menu")
          .show();
        if (transition == "slide") {
          $(this)
            .find(".content")
            .show();
          $(this)
            .find(".content")
            .css("height", "auto");
          var originalHeight = $(this)
            .find(".content")
            .height();
        } else if (transition == "fade") {
          $(this)
            .find(".content")
            .fadeIn(animation_time);
        } else {
          $(this)
            .find(".content")
            .show();
        }
        $(this)
          .children(".sub-menu")
          .css("right", "auto");
        if (
          $("html")
            .css("direction")
            .toLowerCase() == "rtl"
        ) {
          var $whatever = $(this).children(".sub-menu");
          var $whatever2 = $($whatever).closest("ul.megamenu");
          if ($whatever.offset().left < $whatever2.offset().left) {
            $(this)
              .children(".sub-menu")
              .css("right", "0");
          }
        } else {
          var $whatever = $(this).children(".sub-menu");
          var ending_right =
            $(window).width() -
            ($whatever.offset().left + $whatever.outerWidth());
          var $whatever2 = $($whatever).closest("ul.megamenu");
          var ending_right2 =
            $(window).width() -
            ($whatever2.offset().left + $whatever2.outerWidth());
          if (ending_right2 > ending_right) {
            $(this)
              .children(".sub-menu")
              .css("right", "0");
          }
        }
      });
    }

    function hoverMegaMenu() {
      $("ul.megamenu > li.hover").hover(
        function() {
          active = $(this);
          hover = true;
          var transition = $(this)
            .closest(".megamenu")
            .data("transition");
          var animation_time = $(this)
            .closest(".megamenu")
            .data("animationtime");
          $("ul.megamenu > li").removeClass("active");
          $(this).addClass("active");
          $("ul.megamenu > li")
            .children(".sub-menu")
            .hide();
          $("ul.megamenu > li")
            .find(".content")
            .hide();
          $(this)
            .children(".sub-menu")
            .show();
          if (transition == "slide") {
            $(this)
              .find(".content")
              .show();
          } else if (transition == "fade") {
            $(this)
              .find(".content")
              .fadeIn(animation_time);
          } else {
            $(this)
              .find(".content")
              .show();
          }
          $(this)
            .children(".sub-menu")
            .css("right", "auto");
          if (
            $("html")
              .css("direction")
              .toLowerCase() == "rtl"
          ) {
            var $whatever = $(this).children(".sub-menu");
            var $whatever2 = $($whatever).closest("ul.megamenu");
            if ($whatever.offset().left < $whatever2.offset().left) {
              $(this)
                .children(".sub-menu")
                .css("right", "0");
            }
          } else {
            var $whatever = $(this).children(".sub-menu");
            var ending_right =
              $(window).width() -
              ($whatever.offset().left + $whatever.outerWidth());
            var $whatever2 = $($whatever).closest("ul.megamenu");
            var ending_right2 =
              $(window).width() -
              ($whatever2.offset().left + $whatever2.outerWidth());
            if (ending_right2 > ending_right) {
              $(this)
                .children(".sub-menu")
                .css("right", "0");
            }
          }
        },
        function() {
          var rel = $(this).attr("title");
          hover = false;
          var transition = $(this)
            .closest(".megamenu")
            .data("transition");
          var animation_time = $(this)
            .closest(".megamenu")
            .data("animationtime");
          if (rel == "hover-intent") {
            var hoverintent = $(this);
            setTimeout(function() {
              if (hover == false) {
                if (transition == "slide") {
                  $(hoverintent)
                    .find(".content")
                    .stop(true, true)
                    .animate({ height: "hide" }, animation_time, function() {
                      if (hover == false) {
                        $(hoverintent).removeClass("active");
                        $(hoverintent)
                          .children(".sub-menu")
                          .hide();
                      }
                    });
                } else if (transition == "fade") {
                  $(hoverintent).removeClass("active");
                  $(hoverintent)
                    .find(".content")
                    .fadeOut(animation_time, function() {
                      if (hover == false) {
                        $(hoverintent)
                          .children(".sub-menu")
                          .hide();
                      }
                    });
                } else {
                  $(hoverintent).removeClass("active");
                  $(hoverintent)
                    .children(".sub-menu")
                    .hide();
                  $(hoverintent)
                    .find(".content")
                    .hide();
                }
              }
            }, 500);
          } else {
            if (transition == "slide") {
              $(this)
                .find(".content")
                .stop(true, true)
                .animate({ height: "hide" }, animation_time, function() {
                  if (hover == false) {
                    $(active).removeClass("active");
                    $(active)
                      .children(".sub-menu")
                      .hide();
                  }
                });
            } else if (transition == "fade") {
              $(active).removeClass("active");
              $(this)
                .find(".content")
                .fadeOut(animation_time, function() {
                  if (hover == false) {
                    $(active)
                      .children(".sub-menu")
                      .hide();
                  }
                });
            } else {
              $(this).removeClass("active");
              $(this)
                .children(".sub-menu")
                .hide();
              $(this)
                .find(".content")
                .hide();
            }
          }
        }
      );
    }

    // $(document).ready(function(){}
  },
  created() {
    this.handleHeaderTabs();
    this.hideProfileLbl();
    this.getCategories();
    this.setCartItems();
    this.getLoginInfo();
    this.init();
  },
  methods: {
    homeTabclick(id) {
      this.$router.push({ name: "Product List Home Tabs", query: { id: id } });
    },
    handleHeaderTabs: async function() {
      try {
        let response = await this.getHomeTabProducts();
        var currentLogoSectionHeight = document.getElementById("comminglogosection")
          .offsetHeight;
        var searchSectionHeight = document.getElementById("commn-search-col")
          .offsetHeight;
        var homeTabHeight = document.getElementById("hometabs").offsetHeight;

        var remainingHeight =
          currentLogoSectionHeight - (searchSectionHeight + homeTabHeight);

        // document.getElementById("hometabs").style.paddingTop =
        //   remainingHeight + "px";
        console.log(homeTabHeight);
        this.homeTabData = response;
      } catch (error) {
        console.log(error);
      }
    },
    menuBannerStatus() {
      if (!document.body.classList.contains("common-home")) {
        this.menubanner = false;
      } else {
        this.menubanner = true;
      }
    },
    handdleClickSathosa: function() {
      this.$router.push("productsearch") ;
    },
    // getProfileData: async function() {
    //     try {
    //         let response = await this.getProfileBacicInfo();
    //         this.buyer = response.first_name + " " + response.last_name;
    //     } catch (error) {
    //         throw error;
    //     }
    // },
    getUserCreditLimit: async function() {
      try {
        let response = await this.getCreditLimit();
        this.credit =
          response.credit != null || undefined ? response.credit : "";
      } catch (error) {
        throw error;
      }
    },
    hideProfileLbl() {
      if (this.buyer) {
        // document.getElementById("profileLbl").style.display = "none";
      }
    },
    init() {
      let user = "";
      user = JSON.parse(localStorage.getItem("buyer_data"));
      if (user.credit < 0) {
        this.credit = "";
      } else {
        this.credit = user.credit;
      }
      this.userRole = user.user_role != undefined ? user.user_role : "";
      if (user.first_name !== undefined && user.last_name !== undefined) {
        this.buyer = user.first_name + " " + user.last_name;
      } else {
        this.getLoginInfo();
      }
    },
    clearmsg() {
      this.trackid = "";
      this.trackmessage = "";
    },
    trackMyOrder: async function() {
      if (this.trackid === "") {
        this.trackmessage = "Enter Order ID";
        return;
      }

      try {
        let response = await this.getOrderList();

        response.forEach((element) => {
          if (element.order_env_number === this.trackid) {
            $("#mdlTrackOrder").modal("hide");
            this.trackid = "";
            this.trackmessage = "";
            this.$router
              .push("/buyer-order-configure/" + element._id)
               ;
            return;
          }
        });

        this.trackmessage = "Order Not Found!";
      } catch (error) {
        throw error;
      }
    },
    validateSearch() {
      if (this.cid === "" && this.searchtxt === "") {
        this.searchdisable = true;
      } else {
        this.searchdisable = false;
      }
    },
    pushSearch() {
      let params = "";
      if (this.searchtxt !== "") {
        params += "&name=" + this.searchtxt;
      }

      if (this.cid !== "") {
        params += "&id=" + this.cid;
      }
      this.$router.push("/productsearch?" + params) ;
    },
    pushMobileSearch(id) {
      let params = "";
      if (id !== "") {
        params += "&id=" + id;
      }
      this.$router.push("/productsearch?" + params) ;
    },

    loginNew() {
      this.$refs.active.activetab = 1;
      $("#BuyerLoginModal").modal("show");
      if (this.$refs.active.showforgotpass === true) {
        // hide forget password modal when login
        this.$refs.active.showforgotpass = false;
      }
    },
    logToCart() {
      if (this.no_of_cart_items > 0) {
        this.$router.push("/buyer-cart") ;
      }
    },
    myOrders() {
      this.$router.push("/") ;
    },
    myOrderList() {
      this.$router.push("buyer-orders") ;
    },
    disputeReturn() {
      this.$router.push("/buyer-return-orders") ;
    },
    myReviews() {
      this.$router.push("/buyer-my-reviews") ;
    },
    DisputeReturn() {
      this.$router.push("buyer-return-orders") ;
    },
    BuyerMyReviews() {
      this.$router.push("buyer-my-reviews") ;
    },
    myAccount() {
      this.$router.push("me");
    },
    home() {
      this.$router.push("/") ;
    },
    cart() {
      this.$router.push("/buyer-cart") ;
    },
    wishlist() {
      this.$router.push("buyer-wishlist") ;
    },
    login() {
      this.$router.push("/buyer-login") ;
    },
    logout() {
      // $('BuyerLoginModall').modal('hide');
      this.$router.push("/") ;

      this.$store.dispatch("logoutbuyer");
      localStorage.removeItem("cart_product_ids");
      localStorage.removeItem("buyer_data");
      localStorage.removeItem("buyer_token");
      localStorage.removeItem("buyer_type");
      localStorage.removeItem("login_buyer");
      localStorage.removeItem("agent_guestdata");
      location.reload(true);
    },
    register() {
      this.$router.push("/buyer-register") ;
    },
    deliverLanding() {
      this.$router.push("/deliver-login") ;
    },
    merchantLanding() {
      this.$router.push("/merchant-landing-new") ;
    },
    merchantLandingnew() {
      this.$router.push("/merchant-landing-new") ;
    },
    handleProfile() {
      if (!localStorage.buyer_data) {
        this.$router.push("/buyer-profile") ;
        return;
      }
      let user = JSON.parse(localStorage.getItem("buyer_data"));
      this.userRole = user.user_role != undefined ? user.user_role : "";
      if (this.userRole == 2) {
        this.$router.push("/buyer-orders") ;
        return;
      } else {
        this.$router.push("/buyer-profile") ;
      }
    },
    viewProfile: function() {
      this.$router.push("/buyer-profile") ;
    },
    getLoginInfo: async function() {
      if (this.$store.state.buyer_accessToken === null) {
        return;
      }

      // this.getUserCreditLimit();
      try {
        let response = await this.getProfileBacicInfo();
        if (response.image) {
          this.image = response.image;
        }
        this.buyer = response.first_name + " " + response.last_name;
      } catch (error) {
        throw error;
      }
    },
    getCategories: async function() {
      try {
        let status = await this.getMainCategories();
        console.log("cat view")
        console.log(status)
        this.mainCategory = status;
        // let main_cat = status.sub_category_one;

        // this.mainCategory = status[0].sub_category_one;

        // for (let index = 0; index < this.mainCategory.length; index++) {
        //   const element = this.mainCategory[index];
        //   let new_sub_one = element.sub_category_two;
        //   element.sub_category_one = new_sub_one;
        // }
      } catch (error) {
        throw error;
      }
    },
    pushTocategoryMain(id, str) {
      //Hide Menu
      $(".vertical-wrapper").removeClass("so-vertical-active");
      $(".content")
        .filter(function() {
          return $(this).css("display") == "block";
        })
        .css("display", "none");

      localStorage.setItem("categoryName", str);
      this.$store.commit("updateCategoryName", str);
      this.$router
        .push({ name: "Product List Main", query: { id: id } })
         ;
    },
    pushTocategory(id, str) {
      //Hide Menu
      $(".vertical-wrapper").removeClass("so-vertical-active");
      $(".content")
        .filter(function() {
          return $(this).css("display") == "block";
        })
        .css("display", "none");
      localStorage.setItem("categoryName", str);
      this.$store.commit("updateCategoryName", str);
      this.$router
        .push({ name: "Product List", query: { id: id } })
         ;
    },
    pushToProductList(id) {
      $(".content")
        .filter(function() {
          return $(this).css("display") == "block";
        })
        .css("display", "none");
      this.$router
        .push({ name: "Product List", query: { id: id } })
         ;
    },
    mouseOverMenu() {
      if (this.menuloaded === false) {
        $(".vertical .sub-menu").each(function() {
          let value = $(this).data("subwidth");
          if (value) {
            var container_width = $(".container").width();
            var vertical_width = $(".vertical").width();
            var full_width = container_width - vertical_width;
            var width_submenu = (full_width * value) / 100;
            $(this).css("width", width_submenu + "px");
          }
        });

        $("ul.megamenu > li.hover").hover(
          function() {
            active = $(this);
            hover = true;
            var transition = $(this)
              .closest(".megamenu")
              .data("transition");
            var animation_time = $(this)
              .closest(".megamenu")
              .data("animationtime");
            $("ul.megamenu > li").removeClass("active");
            $(this).addClass("active");
            $("ul.megamenu > li")
              .children(".sub-menu")
              .hide();
            $("ul.megamenu > li")
              .find(".content")
              .hide();
            $(this)
              .children(".sub-menu")
              .show();
            if (transition == "slide") {
              $(this)
                .find(".content")
                .show();
            } else if (transition == "fade") {
              $(this)
                .find(".content")
                .fadeIn(animation_time);
            } else {
              $(this)
                .find(".content")
                .show();
            }
            $(this)
              .children(".sub-menu")
              .css("right", "auto");
            if (
              $("html")
                .css("direction")
                .toLowerCase() == "rtl"
            ) {
              var $whatever = $(this).children(".sub-menu");
              var $whatever2 = $($whatever).closest("ul.megamenu");
              if ($whatever.offset().left < $whatever2.offset().left) {
                $(this)
                  .children(".sub-menu")
                  .css("right", "0");
              }
            } else {
              var $whatever = $(this).children(".sub-menu");
              var ending_right =
                $(window).width() -
                ($whatever.offset().left + $whatever.outerWidth());
              var $whatever2 = $($whatever).closest("ul.megamenu");
              var ending_right2 =
                $(window).width() -
                ($whatever2.offset().left + $whatever2.outerWidth());
              if (ending_right2 > ending_right) {
                $(this)
                  .children(".sub-menu")
                  .css("right", "0");
              }
            }
          },
          function() {
            var rel = $(this).attr("title");
            hover = false;
            var transition = $(this)
              .closest(".megamenu")
              .data("transition");
            var animation_time = $(this)
              .closest(".megamenu")
              .data("animationtime");
            if (rel == "hover-intent") {
              var hoverintent = $(this);
              setTimeout(function() {
                if (hover == false) {
                  if (transition == "slide") {
                    $(hoverintent)
                      .find(".content")
                      .stop(true, true)
                      .animate({ height: "hide" }, animation_time, function() {
                        if (hover == false) {
                          $(hoverintent).removeClass("active");
                          $(hoverintent)
                            .children(".sub-menu")
                            .hide();
                        }
                      });
                  } else if (transition == "fade") {
                    $(hoverintent).removeClass("active");
                    $(hoverintent)
                      .find(".content")
                      .fadeOut(animation_time, function() {
                        if (hover == false) {
                          $(hoverintent)
                            .children(".sub-menu")
                            .hide();
                        }
                      });
                  } else {
                    $(hoverintent).removeClass("active");
                    $(hoverintent)
                      .children(".sub-menu")
                      .hide();
                    $(hoverintent)
                      .find(".content")
                      .hide();
                  }
                }
              }, 500);
            } else {
              if (transition == "slide") {
                $(this)
                  .find(".content")
                  .stop(true, true)
                  .animate({ height: "hide" }, animation_time, function() {
                    if (hover == false) {
                      $(active).removeClass("active");
                      $(active)
                        .children(".sub-menu")
                        .hide();
                    }
                  });
              } else if (transition == "fade") {
                $(active).removeClass("active");
                $(this)
                  .find(".content")
                  .fadeOut(animation_time, function() {
                    if (hover == false) {
                      $(active)
                        .children(".sub-menu")
                        .hide();
                    }
                  });
              } else {
                $(this).removeClass("active");
                $(this)
                  .children(".sub-menu")
                  .hide();
                $(this)
                  .find(".content")
                  .hide();
              }
            }
          }
        );
        this.menuloaded = true;
      }
    },
    setCartItems: async function() {
      let res = JSON.parse(localStorage.getItem("cart_product_ids"));
      if (res !== null) {
        this.cart_products = res.ids;
      }
      this.no_of_cart_items = this.cart_products.length;
    },
    setCartItemsStore: async function(obj) {
      this.cart_products = obj.ids;
      this.no_of_cart_items = obj.ids.length;
    },
    removeCartItems: async function(itemObj, index) {
      let cart_items = JSON.parse(localStorage.cart_product_ids);

      cart_items.ids.splice(index, 1);

      localStorage.removeItem("cart_product_ids");

      if (cart_items.ids.length === 0) {
        this.idObj.ids = [];
        localStorage.setItem("cart_product_ids", JSON.stringify(this.idObj));
      } else {
        this.idObj.ids = [];
        cart_items.ids.map((obj) => {
          this.idObj.ids.push(obj);
          localStorage.setItem("cart_product_ids", JSON.stringify(this.idObj));
        });
      }

      this.no_of_cart_items = cart_items.ids.length;

      this.setCartItems();
    },
    handdleClickFlashDeals: function() {
      this.$router.push("/flashDeal");
    },
    pushToItem(item_id) {
      this.$router.push({ path: "product", query: { pid: item_id } });
    },
  },
};
</script>
<style>
/* home page top header category set fixed height and position absolute according to body */
.container-megamenu.vertical .vertical-wrapper ul.megamenu > li {
  position: static;
}

.subNavLink a{
  cursor: default !important ;
}


.container-megamenu.vertical
  .vertical-wrapper
  ul.megamenu
  > li
  > .sub-menu
  .content {
  height: 500px;
  overflow-y: scroll;
}
/* ************************************************** */

#comminglogosection {
  height: 163px;
  background-image: url('https://res.cloudinary.com/persystance-network/image/upload/v1608183515/cochchi/portal_user/1608183514902.png') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position-x: left !important;
  background-position-y: center !important;
  margin-bottom: -36px;
  margin-left: 14px;
}

.comming_nav li a{
  color: white !important;
}

.comming_nav li a:hover{
   color: white !important;
}
.menu-profile {
  top: 109% !important;
  right: 10% !important;
  left: auto;
  color: #666;
  min-width: 200px !important;
}

#buyerMenu {
  min-width: 100px;
  right: auto;
  left: auto;
  top: 70px;
}

#buyer-prof-newloggedinmain-ul li a {
  color: black !important;
}

#buyer-prof-newloggedinmain-ul li a:hover {
  color: #005baa !important;
}

.devider-profile {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}
.devider-profile1 {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

.devider-profile1:focus {
  background-color: red !important;
}
.user-name {
  font-size: 12px;
  color: white !important;
  margin-right: 10px;
}
.logo-bg {
  position: absolute;
  background-color: #f34b34;
  left: -20px;
  padding-left: 50px;
  top: -20px;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-right: 80px;
  border-bottom-right-radius: 90px;
}

.text-color-headder {
  /* color: black; */
}

#madeSLLiSoon img{
  height: 45px;
  margin-top: -10px;
}

.login-signup-text{
  color: #222222 !important;
}
.login-signup-text:hover{
  text-decoration: underline;
}

.cart-item-count{
  background: #5779ae;
  color: #ffffff !important;
  font-weight: 600;
}

.middle-right {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 0px;
  margin-right: -10px;
}
.logo-bg img {
  width: 100%;
}
.fa-lock:hover {
  color: #5779ae;
}

/* New header adjustment styles */
.newlogo-block {
  overflow: hidden;
  padding-left: 0px;
}
#buyerhdrlogo {
  width: 100%;
  height: 75px;
  margin: 0 0 0 -20px;
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
  background: #de1a22;
  overflow: hidden;
  position: relative;
}
#buyerhdrlogo img {
  display: block;
  width: 50%;
  margin: 10px auto 0 auto;
  -webkit-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -o-transform: skew(-20deg);
}
.new-search-area-block {
  padding-top: 25px;
  text-align: center;
}
.new-search-area-block #sosearchpro .select_category {
  display: block;
}
.new-icon-area-block {
  padding-top: 25px;
}
.new-text-color-headder{
  color: #000000 !important;
}
.typeheader-1 .new-signin-w ul li a{
  color: #000000;
}
.logosection-dashboard img{
  max-width: 48%;
  margin-left: 12%;
  margin-top: 6%;
}
.main-menu-w{
  display: none;
}
.wishlist-comp-new li a .icon-block .fa{
  color: #000000;
  font-size: 32px;
}
.wishlist-comp-new li a .icon-block .fa:hover{
    color: #5779ae;
}

.wishlist-comp-new li a .icon-block .fa:focus{
    color: #5779ae;
}

.shopping_cart_profhdr{
  display: flex;
  justify-content: flex-end!important;
  text-align: right;
  width: 100% !important;
}
.shopping_cart_profhdr .btn-shopping-cart_profhdr{
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%
}

.shopping_cart_profhdr .btn-shopping-cart_profhdr a{
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  justify-content: flex-end;
}
.shopping_cart_profhdr .btn-shopping-cart_profhdr .shopcart_profhd{
  display: flex;
  flex-wrap: wrap;
  /* flex-basis: 50%; */
}
.shopping_cart_profhdr .btn-shopping-cart_profhdr .shopcart_profhd ul.wishlist-comp_profhd{
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
}
.shopping_cart_profhdr .btn-shopping-cart_profhdr .shopcart_profhd ul li{
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
}
.shopping_cart_profhdr .btn-shopping-cart_profhdr .shopcart_profhd ul li .cart-count-block{
  display: flex;
  flex-wrap: wrap;
  /* flex-basis: 50% !important; */
  /* outline: 1px solid green; */
}
.shopping_cart_profhdr .btn-shopping-cart_profhdr .shopcart_profhd ul li .cart-count-block .shopcart-inner{
    display: flex;
    flex-basis: 100%;
    justify-content: flex-end;
    position: relative;
    margin-left: 0px;
    float: none;
    margin-right: 0px !important;
}
.shopping_cart_profhdr .btn-shopping-cart_profhdr .shopcart_profhd ul li .cart-count-block .shopcart-inner .total-shopping-cart{
  right: 43px !important;
  left: auto !important;
}
.shopping_cart_profhdr .btn-shopping-cart_profhdr .shopcart_profhd ul li .my-cart-btn-block{
  display: flex;
  flex-wrap: wrap;
  flex-basis: 50% !important;
  /* outline: 1px solid red; */
}
.buyer-prof-cartbuttondiv{
  padding-right: 0px;
  /* outline: 1px solid red; */
}
.typeheader-1 .buyer-prof-cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li .my-cart-btn-block button{
  padding-right: 0px;
}
.typeheader-1 .buyer-prof-cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li .buyer-prof-cart-count-block{
  /* margin-right: -75px; */
}
.typeheader-1 .shopping_cart .btn-shopping-cart .top_cart .shopcart-inner .total-shopping-cart .buyer-prof-items_cart{
  left: 0px;
}
.buyer-prof-newlink-list ul{
  text-align: right;
  display: block !important;
  text-align: right;
  width: 100%;
}
.buyer-prof-newlink-list ul li ul{
  width: 190px;
  position: absolute;
  z-index: 1000000;
  background: #ffffff;
  border-radius: 4px;
  display: none !important;
  -webkit-box-shadow: 0px 0px 4px 3px rgba(176,176,176,0.21);
-moz-box-shadow: 0px 0px 4px 3px rgba(176,176,176,0.21);
box-shadow: 0px 0px 4px 3px rgba(176,176,176,0.21);
}
.buyer-prof-newlink-list ul li{
  position: relative;
  margin-left: 0px !important;
  padding-left: 2% !important;
  padding-right: 2% !important;
  border: none !important;
  /* outline: 1px solid red; */
}
.buyer-prof-newlink-list ul li:last-child{
  padding-right: 0px !important;
}
.buyer-prof-newlink-list ul li:hover > ul{
  display: block !important;
}
.buyer-prof-newlink-list ul li ul li{
   padding: 0px !important;
   margin-bottom: 1px;
}
.buyer-prof-newlink-list ul li ul li::after{
  content: "";
  width: 90%;
  height: 1px;
  display: block;
  margin: 0 auto;
  background: #f0f0f0;
}
.buyer-prof-newlink-list ul li ul li:last-child::after{
  background: transparent;
}
.buyer-prof-newlink-list ul li ul li a{
  display: block;
  padding: 12px 15px;
  text-align: left;
  /* padding: 12px 12px; */
  line-height: 16px;
  color: #222222; 
  font-size: 13px;
  font-weight: bold;
}
.buyer-prof-newlink-list ul li ul li a .fa{
  margin-right: 15px;
}
.buyer-prof-newlink-list ul li ul li a:hover{
  color: #005baa;
}
.typeheader-1 .buyer-prof-newlink-list ul.top-link > li::after {
  display: none;
}

/* ASHEN CSS */
.nav-logo {
  width: 100%;
  background: #de1a22;
  overflow: hidden;
  position: relative;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.addToCartNew {
  background: #ffd338 !important;
  font-size: 12px;
  font-weight: 600;
  color: #333333;
  text-transform: capitalize;
  border-radius: 6px;
  border: none;
  padding: 0 10px;
  height: 34px;
  line-height: 34px;
  line-height: 100%;
  border: none;
}
.addToCartNew:hover {
  color: #ffffff !important;
}
 .typeheader-1 .buyer-prof-menu-vertical{
      width: 20% !important;
 }

/* =================== Media queries */
@media screen and (max-width: 1650px) {
  #comminglogosection {
    height: 154px;
  }
}

@media screen and (max-width: 1600px) {
  #comminglogosection {
    height: 149px;
  }
}

@media screen and (max-width: 1500px) {
  #comminglogosection {
    height: 139px;
  }
}

@media screen and (max-width: 1440px) {
  #comminglogosection {
      height: 133px;
  }
}

@media screen and (max-width: 1400px) {
  #comminglogosection {
    height: 127px;
  }
}

@media screen and (max-width: 1440px) {
  .typeheader-1 .header-middle {
    margin: 0px;
  }

  .logo-bg img {
    max-width: 310px;
  }
}

@media screen and (max-width: 1400px) {
  #comminglogosection {
    height: 127px;
  }
}

@media screen and (max-width: 1366px) {
  #comminglogosection {
    height: 122px;
  }
}

@media screen and (max-width: 1300px) {
  #comminglogosection {
      height: 117px;
  }
}

@media screen and (max-width: 1280px) {
  #comminglogosection {
    height: 114px;
  }
}

@media screen and (max-width: 1275px) {
  .buyer-prof-newlink-list ul{
    width: 100%;
  }
  .buyer-prof-newlink-list ul li{
    padding-left: 8px;
    padding-right: 8px;
  }
}


@media screen and (max-width: 1200px) {
  .shopping_cart_profhdr .btn-shopping-cart_profhdr .shopcart_profhd{
    /* flex-basis: 70%; */
  }
  .typeheader-1 .buyer-prof-searchdiv{
    padding-top: 40px;
  }
  .buyer-prof-header-top{
    padding-top: 0px;
  }
  .typeheader-1 .buyer-prof-menu-vertical{
    width: 25% !important;
  }

  #comminglogosection {
    height: 117px;
  }

}

@media screen and (max-width: 1190px) {
  .buyer-prof-header-top{
    padding-top: 12px;
  }

  #comminglogosection {
    height: 90px;
  }

}

@media screen and (max-width: 1024px) {
  #comminglogosection {
    height: 100px;
  }

  .buyer-prof-header-top{
    padding-top: 3%;
  }
}


@media screen and (max-width: 992px) {
  .newlogo-block {
    width: 40%;
  }
  #buyerhdrlogo img {
    width: 50%;
  }
  .typeheader-1 .buyer-prof-cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li .my-cart-btn-block{
    display: flex;
    justify-content: flex-end;
    padding-right: 12px;
  }
  .typeheader-1 .buyer-prof-cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li .cart-count-block{
    margin-right: 0px;
  }
  .shopping_cart_profhdr .btn-shopping-cart_profhdr .shopcart_profhd ul li .my-cart-btn-block{
    flex-basis: 15% !important;
  }
  .buyer-prof-newlink-list{
    padding-right: 10px;
  }
  .logosection-dashboard{
    width: 20% !important;
    /* outline: 1px solid orange; */
  }
  .buyer-prof-searchdiv{
    width: 50% !important;
    /* outline: 1px solid green; */
   }
    .typeheader-1 .buyer-prof-cartbuttondiv{
     width: 28% !important;
     padding-top: 35px !important;
     /* outline: 1px solid red; */
   }
   .logosection-dashboard img{
     width: 60% !important;
   }
  .typeheader-1 .buyer-prof-menu-vertical{
    width: 100% !important;
  }

  #comminSoonContainer {
    width: auto;
  }

  #comminglogosection {
   margin-left: 0%;
  }

}

@media (max-width: 850px) {
  #comminglogosection {
    height: 88px;
  }

  .logosection-dashboard img{
    max-width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .logo-bg {
    padding-right: 50px;
  }
  .logo-bg img {
    width: 55%;
  }

  #comminglogosection {
    height: 124px;
    background-size: auto !important;
  }

  .logosection-dashboard img{
    max-width: 16%;
    margin-top: 1%;
    margin-left: 6%;
  }

  .comming-header-top-right {
    padding-right: 6%;
  }

  .typeheader-1 .buyer-prof-cartbuttondiv{
     padding-top: 46px !important;
   }
   .logosection-dashboard{
    width: 100% !important;
  }
  .buyer-prof-searchdiv{
     width: 65% !important;
   }
    .typeheader-1 .buyer-prof-cartbuttondiv{
     width: 35% !important;
   }
   
}
@media screen and (max-width: 675px) {
  .logosection-dashboard img {
    max-width: 19%;
    margin-top: 1%;
    margin-left: 6%;
  }

  .typeheader-1 .buyer-prof-searchdiv {
    padding-top: 8% ;
  }

  #buyerhdrlogo {
    height: 65px;
  }
  .newlogo-block {
    width: 35%;
  }
  .new-search-area-block {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .new-icon-area-block {
    padding-top: 15px;
  }
  #buyerhdrlogo img {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  #hometabs {
    padding-top: 20px;
    position: unset;
  }

  #hometabsbuttons {
    float: left;
    position: relative;
    bottom: auto;
  }
  #buyerName {
    font-size: smaller;
  }
  .user-name {
    font-size: smaller;
    color: white !important;
    margin-right: 5px;
  }
}

@media screen and (max-width: 575px) {
  .logo-bg img {
    width: 75%;
  }

  .logosection-dashboard img {
    max-width: 25%;
    margin-top: 1%;
    margin-left: 6%;
  } 

  .newlogo-block {
    width: 100%;
    padding-right: 0px;
  }
  .new-icon-area-block {
    display: flex;
    justify-content: center;
  }
  #buyerhdrlogo {
    margin-left: 0px;
    -webkit-transform: skew(0deg);
    -moz-transform: skew(0deg);
    -o-transform: skew(0deg);
  }
  #buyerhdrlogo img {
    width: 20%;
    -webkit-transform: skew(0deg);
    -moz-transform: skew(0deg);
    -o-transform: skew(0deg);
  }
  .new-icon-area-block .fa-shopping-cart {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 475px) {
  .logo-bg img {
    width: 100%;
  }

  .logosection-dashboard img {
    max-width: 30%;
    margin-top: 1%;
    margin-left: 6%;
  }

  .typeheader-1 .buyer-prof-cartbuttondiv {
      padding-top: 32px;
  }
}

@media screen and (max-width: 375px) {
  .logosection-dashboard img {
    max-width: 30%;
    margin-top: 4%;
    margin-left: 13%;
  }

  #buyerhdrlogo img {
    width: 30%;
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
  }
  #buyerhdrlogo {
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
  }
   .typeheader-1 .buyer-prof-searchdiv{
    width: 60% !important;
  }
  .typeheader-1 .buyer-prof-cartbuttondiv{
    width: 40% !important;
  }
}

@media screen and (max-width: 320px) {
  .typeheader-1 .buyer-prof-searchdiv{
    width: 100% !important;
  }
  .typeheader-1 .buyer-prof-cartbuttondiv{
    width: 100% !important;
  }


}



/*===============================================================*/

/*===============================================================*/
@media only screen and (min-width: 600px) {
  #hometabs {
    padding-top: 20px;
    position: unset;
  }
  #hometabsbuttons {
    float: left;
    position: relative;
    bottom: auto;
  }
  #buyerName {
    font-size: smaller;
  }
  .user-name {
    font-size: smaller;
    color: white !important;
    margin-right: 5px;
  }
}

@media only screen and (min-width: 768px) {
  #hometabs {
    padding-top: 20px;
    position: unset;
  }
  #hometabsbuttons {
    float: left;
    position: relative;
    bottom: auto;
  }
  #buyerName {
    font-size: inherit;
  }
  .user-name {
    font-size: inherit;
    color: white !important;
    margin-right: 5px;
  }
  .content {
    margin-top: auto;
  }
  .content {
    margin-top: auto;
  }
}

@media only screen and (min-width: 992px) {
  #hometabs {
    position: relative;
  }
  #hometabsbuttons {
    float: left;
    position: relative;
    bottom: auto;
  }
  #hometabsbuttons button {
    min-width: 120px;
  }
  #buyerName {
    font-size: inherit;
  }
  .user-name {
    font-size: inherit;
    color: white !important;
    margin-right: 5px;
  }
  .content {
    margin-top: -40px;
  }
}

@media screen and (min-width: 1024px) {
  .logosection-dashboard{
    width: 20% !important;
  }
  .banner-top-nav-new{
    width: 80%;
  }

}

@media only screen and (min-width: 1200px) {
  #hometabs {
    padding-top: 20px;
    position: relative;
    height: 60px;
  }
  #hometabsbuttons {
    float: left;
    position: absolute;
    bottom: 0px;
  }
  #hometabsbuttons button {
    min-width: 120px;
  }
  #buyerName {
    font-size: inherit;
  }
  .user-name {
    font-size: inherit;
    color: white !important;
    margin-right: 5px;
  }
  .content {
    margin-top: -40px;
  }
}

@media screen and (min-width: 1475px) {
  #buyerhdrlogo img {
    width: 40%;
  }
}
@media screen and (min-width: 1675px) {
  #buyerhdrlogo img {
    width: 47.5%;
  }
}
@media screen and (min-width: 1980px) {
  #buyerhdrlogo img {
    width: 35%;
  }
}
@media screen and (min-width: 2000px) {
  #buyerhdrlogo img {
    width: 55%;
  }
}
</style>
